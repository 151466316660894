<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center">
        <div class="text-h6">Início inteligente</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-body-1 grey--text mb-6">
        Configuração de funcionalidade
      </div>

      <section>
        <v-form v-model="valid" ref="form">
          <v-row class="mb-4">
            <v-col cols="12" md="6" class="pb-0">
              <ColorPicker
                v-model="form.textColor"
                :label="'Cor do texto'"
                :startValue="form.textColor"
                :rules="_rulesColor"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <ColorPicker
                v-model="form.cardColor"
                :label="'Cor de fundo'"
                :startValue="form.cardColor"
                :rules="_rulesColor"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <TimePicker
                v-model="form.startAt"
                :label="'Tempo de início'"
                :startValue="form.startAt"
                :maxTime="video.videoTime"
                :rules="_rules"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <TimePicker
                v-model="form.endAt"
                :label="'Tempo de finalização'"
                :startValue="form.endAt"
                :maxTime="video.videoTime"
                :rules="_rules"
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.title"
                label="Título"
                class="rounded-lg"
                color="secondary"
                :rules="_rules"
                rounded
                filled
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.subtitle"
                label="Subtítulo"
                class="rounded-lg"
                color="secondary"
                :rules="_rules"
                rounded
                filled
              />
            </v-col>
          </v-row>

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Cancelar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Salvar alterações </span>
            </v-btn>
          </div>
        </v-form>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { mask } from "vue-the-mask";
import ColorPicker from "@/components/colorPicker/ColorPicker.vue";
import TimePicker from "@/components/timePicker/TimePicker.vue";

export default {
  components: {
    ColorPicker,
    TimePicker,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      videoID: "",
      form: {
        startAt: "",
        endAt: "",
        title: "",
        subtitle: "",
        cardColor: "",
        textColor: "",
      },
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  directives: {
    mask,
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _rulesColor() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v) ||
          "Hexadecimal inválido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadSmartAutoplay",
          videoID: this.videoID,
          corTexto: this.form.textColor.toUpperCase(),
          corBackground: this.form.cardColor.toUpperCase(),
          textoSuperior: this.form.title,
          textoInferior: this.form.subtitle,
          inicio: this.form.startAt,
          fim: this.form.endAt,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.form = structuredClone(this.video.autoPlay);

      this.videoID = this.video.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
