<template>
  <v-container>
    <div class="d-flex flex-column flex-md-row align-md-center mb-8">
      <div class="text-h5 font-weight-bold mb-4 mb-md-0">Meus domínios</div>

      <v-spacer />

      <v-btn class="text-none black--text" color="primary" @click="handleAdd()">
        <span> Adicionar novo </span>
      </v-btn>
    </div>

    <loader v-if="loading.content" />

    <v-data-table
      v-else
      :headers="headers"
      :items="domains"
      hide-default-footer
      disable-pagination
      class="neutral1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small icon @click="handleDelete(item)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <loader-hover v-if="loading.assign" />

    <AddDomain ref="addDomain" :domains="domains" @success="getDomains()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import AddDomain from "./partials/addDomain/AddDomain.vue";

export default {
  name: "Domains",

  components: {
    AddDomain,
  },

  data() {
    return {
      loading: {
        content: true,
        assign: false,
      },
      headers: [
        {
          text: "Domínio",
          value: "domain",
        },
        {
          text: "Ações",
          value: "actions",
          width: "6rem",
        },
      ],
      domains: [],
    };
  },

  beforeMount() {
    this.getDomains();
  },

  methods: {
    async getDomains() {
      try {
        this.loading.content = true;

        const payload = {
          method: "listDominiosPermitidos",
        };

        const { data } = await request(payload);

        this.domains = data.dominios;
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    handleAdd() {
      this.$refs.addDomain.open();
    },

    handleDelete(data) {
      const index = this.domains.findIndex((el) => el.domain === data.domain);
      this.domains.splice(index, 1);

      this.updateDomains();
    },

    async updateDomains() {
      const payload = {
        method: "uploadDominiosPermitidos",
        dominios: JSON.stringify(this.domains.map((el) => el.domain)),
      };

      await request(payload);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
