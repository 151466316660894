<template>
  <v-dialog v-model="dialog" class="elevation-0" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center">
        <div class="text-h6">Botões de ação</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-body-1 grey--text mb-6">
        Aqui, você pode criar e configurar um botão que direciona seus clientes
        para o checkout. Certifique-se de configurar o botão para que ele
        apareça no momento do "Pitch", garantindo que seus clientes visualizem o
        botão e prossigam para o checkout no momento adequado
      </div>

      <!-- list -->
      <section v-if="view === 'list'">
        <v-list v-if="buttons.length" class="transparent pa-0 mb-8" nav>
          <v-list-item
            v-for="(item, i) in buttons"
            class="neutral3 rounded-lg"
            :key="i"
          >
            <v-list-item-content class="pl-4">
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ item.startTime }} - {{ item.endTime }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action-text class="d-flex align-center">
              <v-btn icon small @click="editButton(item)">
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>

              <v-btn color="red" icon small @click="deleteButton(item.id)">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>

        <div v-else class="mb-8 d-flex align-center justify-center text-center">
          <span class="text-body-1 grey--text"> Nenhum botão criado </span>
        </div>

        <div class="d-flex">
          <v-spacer />

          <v-btn class="text-none mr-4" text @click="close()">
            <span> Fechar </span>
          </v-btn>

          <v-btn
            class="text-none black--text"
            color="primary"
            @click="handleNew()"
          >
            <span> Criar novo </span>
          </v-btn>
        </div>
      </section>

      <!-- add -->
      <section v-if="view === 'new'">
        <v-form v-model="valid" ref="form">
          <v-select
            v-model="form.type"
            class="rounded-lg mb-4"
            label="Posição do botão"
            :items="buttonTypes"
            item-text="label"
            item-value="value"
            rounded
            filled
            dense
          />

          <div
            v-if="form.type === '1'"
            class="d-flex flex-column justify-center align-center mb-14"
          >
            <div class="font-weight-bold mb-4">
              Escolha a localização do botão
            </div>

            <v-item-group v-model="form.position" mandatory>
              <v-row justify="center">
                <v-col cols="6" md="5">
                  <v-card class="px-4" color="neutral3" flat>
                    <v-row justify="start">
                      <v-col v-for="n in 9" :key="n" cols="4">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            :color="active ? 'primary' : 'neutral1'"
                            class="d-flex align-center"
                            dark
                            height="42"
                            width="42"
                            @click="toggle"
                          />
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-item-group>
          </div>

          <v-row class="mb-4">
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.text"
                label="Texto do botão"
                class="rounded-lg"
                color="secondary"
                :rules="_rules"
                rounded
                filled
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <ColorPicker
                v-model="form.textColor"
                :label="'Cor do texto do botão'"
                :startValue="form.textColor"
                :rules="_rulesColor"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <ColorPicker
                v-model="form.color"
                :label="'Cor do botão'"
                :startValue="form.color"
                :rules="_rulesColor"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <TimePicker
                v-model="form.startTime"
                :label="'Tempo de início'"
                :startValue="form.startTime"
                :maxTime="video.videoTime"
                :rules="_rules"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <TimePicker
                v-model="form.endTime"
                :label="'Tempo de finalização'"
                :startValue="form.endTime"
                :maxTime="video.videoTime"
                :disabled="form.continueDisplaying"
                :hideDetails="true"
                :rules="_rules"
              />

              <v-checkbox
                v-model="form.continueDisplaying"
                label="Continuar exibindo após o fim do vídeo"
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="form.redirectURL"
                label="URL de redirecionamento"
                class="rounded-lg"
                color="secondary"
                :rules="_rules"
                rounded
                filled
              />
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-select
                v-model="form.size"
                class="rounded-lg"
                label="Tamanho do botão"
                :items="buttonSizes"
                item-text="label"
                item-value="value"
                rounded
                filled
                dense
              />
            </v-col>
          </v-row>

          <!-- preview -->
          <v-sheet color="neutral3" class="mb-12 pa-6 rounded-lg">
            <div class="d-flex flex-column align-center justify-center">
              <div class="text-body-1 font-weight-bold mb-4">Demonstracão</div>

              <v-btn
                class="text-none"
                target="_blank"
                :color="form.color"
                :href="form.redirectURL"
                :small="form.size === '0'"
                :x-large="form.size === '2'"
              >
                <span :style="`color: ${form.textColor}`">
                  {{ form.text || "Seu botão" }}
                </span>
              </v-btn>
            </div>
          </v-sheet>

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="cancel()">
              <span> Voltar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Adicionar </span>
            </v-btn>
          </div>
        </v-form>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { mask } from "vue-the-mask";
import ColorPicker from "@/components/colorPicker/ColorPicker.vue";
import TimePicker from "@/components/timePicker/TimePicker.vue";

export default {
  components: {
    ColorPicker,
    TimePicker,
  },

  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      view: "list",
      videoID: null,
      buttons: [],
      buttonTypes: [
        {
          label: "Abaixo do vídeo",
          value: "0",
        },
        {
          label: "Dentro do vídeo",
          value: "1",
        },
      ],
      buttonSizes: [
        {
          label: "Pequeno",
          value: "0",
        },
        {
          label: "Médio",
          value: "1",
        },
        {
          label: "Grande",
          value: "2",
        },
      ],
      buttonID: "",
      form: {
        type: "",
        text: "",
        startTime: "",
        endTime: "",
        continueDisplaying: false,
        redirectURL: "",
        size: "1",
        textColor: "",
        color: "",
        position: "0",
      },
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  directives: {
    mask,
  },

  watch: {
    ["video.actionButtons"]() {
      this.buttons = this.video.actionButtons;
    },
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _rulesColor() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v) ||
          "Hexadecimal inválido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadBotaodeAcao",
          videoID: this.videoID,
          tipo: this.form.type,
          continuaExibindo: this.form.continueDisplaying ? "1" : "0",
          inicio: this.form.startTime,
          fim: this.form.endTime,
          tituloHTML: "",
          contentHTML: "",
          botao_texto: this.form.text,
          botao_tamanho: "1",
          botao_link: this.form.redirectURL,
          botao_corTexto: this.form.textColor.toUpperCase(),
          botao_corBotao: this.form.color.toUpperCase(),
          botao_corHover: "#000000",
          botao_posicao:
            this.form.type === "1" ? String(this.form.position + 1) : "0",
          ...(this.buttonID && { botaoAcaoID: this.buttonID }),
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.$refs.form.reset();

        if (this.buttonID) this.buttonID = "";

        this.view = "list";
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async deleteButton(id) {
      try {
        this.loading = true;

        const payload = {
          method: "deleteBotaoAcao",
          videoID: this.videoID,
          botaoAcaoID: id,
        };

        await request(payload);

        this.handleAlert("Removido com sucesso", "success");
        this.$emit("success");
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    editButton(data) {
      this.buttonID = data.id;
      this.form = {
        type: data.type,
        text: data.text,
        startTime: data.startTime,
        endTime: data.endTime,
        redirectURL: data.redirectURL,
        textColor: data.textColor,
        color: data.color,
        position: data.position,
      };

      this.view = "new";
    },

    handleNew() {
      this.form = {
        type: "",
        text: "",
        startTime: "00:00:00",
        endTime: "00:00:00",
        redirectURL: "",
        textColor: "#FFFFFF",
        color: "#8FDF1C",
        position: "0",
      };

      this.view = "new";
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.videoID = this.video.id;
      this.buttons = this.video.actionButtons;
      this.view = "list";
      this.dialog = true;
    },

    cancel() {
      this.$refs.form.reset();
      this.view = "list";
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
