<template>
  <v-overlay style="background-color: #000000bf; z-index: 99999">
    <v-progress-circular indeterminate size="48" color="primary" />
  </v-overlay>
</template>

<script>
export default {};
</script>

<style></style>
