<template>
  <v-dialog v-model="dialog" max-width="45rem">
    <v-card class="rounded-lg pa-6" color="neutral2" width="45rem">
      <div class="d-flex justify-end">
        <v-icon @click="close()">mdi-close</v-icon>
      </div>

      <div class="d-flex justify-center flex-column align-center">
        <div class="text-h5 font-weight-bold mb-1">
          Você pode fazer o upload do seu video
        </div>

        <div class="primary--text mb-6">Preencha o formulario abaixo</div>

        <v-form v-model="valid" ref="form" class="fill-width">
          <v-text-field
            v-model="form.title"
            label="Título do seu vídeo"
            class="rounded-lg mb-4"
            :rules="_rules"
            rounded
            filled
            dense
          />

          <v-text-field
            v-model="form.youtubeRef"
            label="URL do vídeo no YouTube"
            class="rounded-lg mb-4"
            placeholder="URL ou ID do seu vídeo"
            :rules="_rules"
            rounded
            filled
            dense
          />

          <div class="d-flex justify-center">
            <v-btn
              class="rounded-lg text-none black--text"
              color="primary"
              depressed
              x-large
              @click="submit()"
            >
              Adicionar Vídeo
            </v-btn>
          </div>
        </v-form>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      form: {
        title: "",
        youtubeRef: "",
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "createNewVideo",
          name: this.form.title,
          youtubeID: this.form.youtubeRef,
        };

        await request(payload);

        this.handleAlert("Vídeo adicionado com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.dialog = true;
      this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
