<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Excluir vídeo</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section>
        <div class="text-body-1 mb-6">
          <span class="red--text font-weight-bold">Atenção!</span> Esta ação é
          irreversível, o vídeo será excluído permanentemente!
        </div>

        <div class="d-flex">
          <v-spacer />

          <v-btn class="text-none mr-4" text @click="close()">
            <span> Cancelar </span>
          </v-btn>

          <v-btn
            class="text-none black--text"
            color="primary"
            @click="submit()"
          >
            <span> Confirmar </span>
          </v-btn>
        </div>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteVideo",
          videoID: this.video.id,
        };

        await request(payload);

        this.handleAlert("Vídeo excluido com sucesso", "success");
        this.$router.push({ path: "/home" });
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
