<template>
  <v-menu
    v-model="menu"
    content-class="mt-16"
    max-width="20rem"
    :close-on-content-click="false"
    :attach="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="model"
        v-bind="attrs"
        v-on="on"
        placeholder="Hexadecimal"
        class="rounded-lg"
        color="secondary"
        :label="label"
        :rules="rules"
        readonly
        rounded
        filled
      >
        <template v-slot:prepend-inner>
          <v-avatar class="mr-2 mt-1" size="16" :color="model" />
        </template>

        <template v-slot:append>
          <v-icon dark>mdi-format-color-fill</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-card class="rounded-lg" color="neutral3">
      <section class="pa-4 pb-0">
        <v-color-picker v-model="model" class="transparent" @input="submit()" />
      </section>

      <div class="d-flex pa-4">
        <v-spacer />

        <v-btn class="text-none" text @click="close()"> Fechar </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      model: "#FFFFFF",
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },

    rules: {
      type: Array,
      default: () => [],
    },

    startValue: {
      type: String,
      default: "",
    },
  },

  mounted() {
    if (this.startValue) {
      this.model = this.startValue;
    }

    this.submit();
  },

  methods: {
    submit() {
      this.$emit("input", this.model);
    },

    close() {
      this.menu = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
