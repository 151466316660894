<template>
  <div class="d-flex fill-height neutral2 py-12">
    <v-container fill-height>
      <router-view />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style src="./style.scss" lang="scss" scoped />
