<template>
  <div class="d-flex align-center justify-center mb-6">
    <v-chip-group
      v-model="selected"
      active-class="primary--text"
      show-arrows
      mandatory
    >
      <v-chip
        v-for="period in periods"
        class="neutral2"
        :disabled="disabled"
        :key="period"
        label
      >
        {{ period }}
      </v-chip>

      <v-dialog ref="dialog" v-model="modal" persistent width="322px">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" class="neutral2" :disabled="disabled" label>
            <span> Definir Período </span>

            <span v-if="selected === 7 && dates[0] && dates[1]" class="ml-2">
              ({{ dates[0] }} - {{ dates[1] }})
            </span>
          </v-chip>
        </template>

        <v-card class="rounded-lg" color="neutral3">
          <section class="pa-4 pb-0">
            <v-date-picker
              v-model="dates"
              color="primary"
              :max="maxDate"
              scrollable
              no-title
              range
            />
          </section>

          <div class="d-flex pa-4">
            <v-spacer />

            <v-btn class="text-none" plain @click="cancelRange()">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!_validRange"
              class="text-none black--text"
              color="primary"
              depressed
              @click="rangeSubmit()"
            >
              Aplicar
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-chip-group>
  </div>
</template>

<script>
const moment = require("moment");

export default {
  data() {
    return {
      modal: false,
      periods: ["Tudo", "Hoje", "Ontem", "Ùltimos 7 dias"],
      selected: 0,
      dates: ["", ""],
      appliedDates: ["", ""],
      maxDate: moment().format(),
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    dates(value) {
      if (value.length > 1) {
        if (value[0] > value[1]) {
          const temp = value[0];

          this.dates.splice(0, 1);
          this.dates.push(temp);
        }
      }
    },

    selected(value) {
      this.handleDate(value);
    },
  },

  computed: {
    _validRange() {
      return this.dates.length && this.dates[0] && this.dates[1];
    },
  },

  methods: {
    submit() {
      const payload = {
        initialDate: this.dates[0],
        finalDate: this.dates[1],
      };

      this.appliedDates = structuredClone(this.dates);

      this.$emit("change", payload);
    },

    handleDate(value) {
      this.dates = [];

      // all
      if (value === 0) {
        this.dates[0] = "";
        this.dates[1] = "";
      }

      // today
      if (value === 1) {
        this.dates[0] = moment().format("YYYY-MM-DD");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // yesterday
      if (value === 2) {
        this.dates[0] = moment().subtract(1, "day").format("YYYY-MM-DD");
        this.dates[1] = moment().subtract(1, "day").format("YYYY-MM-DD");
      }

      // last 7 days
      if (value === 3) {
        this.dates[0] = moment().startOf("week").format("YYYY-MM-DD");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // date pick
      if (value === 4) {
        this.dates[0] = "";
        this.dates[1] = "";

        return;
      }

      this.submit();
    },

    rangeSubmit() {
      this.modal = false;
      this.submit();
    },

    cancelRange() {
      this.dates = structuredClone(this.appliedDates);
      this.modal = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
