<template>
  <v-card color="neutral1" class="rounded-lg pa-6 relative card">
    <div class="d-flex align-center">
      <div class="text-h6 mb-4">Retenção do seu vídeo</div>

      <v-spacer />

      <v-switch
        v-if="_showChart && user.subscriber"
        v-model="showVideo"
        :label="'Exibir vídeo'"
        :disabled="!ready"
      />
    </div>

    <v-responsive
      v-show="_showChart"
      :aspect-ratio="16 / 9"
      class="relative rounded-lg"
    >
      <!-- video -->
      <div ref="player" id="player" class="player" />

      <v-overlay absolute :value="overlay">
        <!-- hide card -->
        <v-card
          v-show="!showVideo || !started"
          width="100%"
          height="100%"
          color="neutral2"
          flat
        />

        <!-- chart overlay-->
        <v-overlay absolute :value="overlay">
          <div class="relative d-flex flex-column fill-height fill-width">
            <!-- chart -->
            <div id="chart" class="chart pr-4">
              <apexchart
                type="area"
                :options="_chartOptions"
                :series="chartSeries"
                height="100%"
                width="100%"
                @click="handleDataPointClick"
              />
            </div>
          </div>
        </v-overlay>
      </v-overlay>
    </v-responsive>

    <!-- empty -->
    <div
      v-if="!_showChart"
      class="d-flex align-center justify-center text-center pb-10"
    >
      <span class="text-body-1 grey--text"> Nenhum dado a ser exibido </span>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue-apexcharts";
const moment = require("moment");

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      overlay: true,
      player: null,
      ready: false,
      showVideo: false,
      started: false,
      chartSeries: [
        {
          name: "Visualizações",
          data: [],
        },
      ],
      categories: [],
    };
  },

  props: {
    retention: {
      type: Object,
      default: () => {},
    },

    videoID: {
      required: true,
    },
  },

  mounted() {
    this.handleData();
  },

  computed: {
    ...mapState(["user"]),

    _showChart() {
      return this.chartSeries[0].data.length;
    },

    _chartOptions() {
      const _self = this;

      return {
        chart: {
          id: "line-chart",
          foreColor: "#ffffff",
          background: "transparent",
          height: "100%",
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        theme: {
          mode: "dark",
          palette: "palette5",
        },
        xaxis: {
          type: "category",
          categories: this.categories,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          ticks: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: true,
            formatter: function (val, { dataPointIndex }) {
              _self.handleXAxisHover(dataPointIndex);
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          show: true,
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return _self.handleFormetterX(val);
            },
          },
          y: {
            formatter: function (val, { dataPointIndex }) {
              return _self.handleFormetterY(val, dataPointIndex);
            },
          },
          style: {
            fontSize: "1rem",
          },
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      };
    },
  },

  watch: {
    showVideo(val) {
      if (!val) {
        this.player.pauseVideo();
      }
    },

    retention: {
      handler() {
        this.handleData();
      },
      deep: true,
    },
  },

  methods: {
    async handleData() {
      if (this.user.subscriber) {
        await this.processChartData(this.retention);
        if (this._showChart && this.user.subscriber) this.renderPlayer();
      }
    },

    processChartData(event) {
      return new Promise((resolve) => {
        const { retention } = event;

        const data = Object.keys(retention).map((key) => retention[key].views);
        const times = Object.keys(retention).map((key) => {
          let value = key;

          if (value.startsWith("00:")) return value.slice(3);
          return value;
        });

        this.chartSeries[0].data = data;
        this.categories = times;

        resolve();
      });
    },

    renderPlayer() {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = this.initPlayer;
    },

    initPlayer() {
      this.player = new window.YT.Player(this.$refs.player, {
        videoId: this.videoID,
        playerVars: {
          enablejsapi: 1,
          html5: 1,
          autoplay: 0,
          controls: 0,
          modestbranding: 1,
          showinfo: 0,
          iv_load_policy: 3,
          showtitle: false,
          disablekb: 1,
          rel: 0,
          fs: 0,
          volume: 0,
          start: 0,
        },
        events: {
          onReady: this.onPlayerReady,
        },
      });
    },

    onPlayerReady() {
      this.ready = true;

      setTimeout(async () => {
        this.showVideo = true;

        this.player.setVolume(0);
        this.player.playVideo();

        if (!this.started)
          setTimeout(() => {
            this.started = true;
          }, 500);

        setTimeout(() => {
          this.player.pauseVideo();
        }, 500);
      }, 1000);
    },

    seekTo(value) {
      this.player.seekTo(value / 1000);
    },

    handleDataPointClick(_, __, config) {
      if (!this.ready || !this.user.subscriber) return;

      const retentions = Object.keys(this.retention.retention);
      const time =
        this.retention.retention[retentions[config.dataPointIndex]].time;
      const duration = moment.duration(time).asMilliseconds();

      if (!this.showVideo) {
        this.showVideo = true;
      }

      this.seekTo(duration);
    },

    handleFormetterX(index) {
      const retentionKeys = Object.keys(this.retention.retention);
      const retention = this.retention.retention[retentionKeys[index]];

      return retention?.time || "";
    },

    handleFormetterY(val, index) {
      const retentionKeys = Object.keys(this.retention.retention);
      const retention = this.retention.retention[retentionKeys[index]];

      return `${val} (${retention.percentage}%)`;
    },

    handleXAxisHover(index) {
      if (!this.ready || !this.user.subscriber) return;

      const time = this.categories[index];

      const regex = new RegExp(":", "g");
      const match = time.match(regex);
      const counter = match ? match.length : 0;

      const format = counter >= 2 ? "HH:mm:ss" : "mm:ss";
      const dataValue = moment(time, format);
      const milliseconds = dataValue.diff(moment().startOf("day"));

      this.seekTo(milliseconds);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
