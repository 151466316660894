<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center">
        <div class="text-h6">Definicões de Cloacker</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-body-1 grey--text mb-6">
        Exiba um vídeo aleatório e proteja sua VSL com essa funcionalidade.
        Defina parâmetros como país e dispositivos. Além disso, você pode
        bloquear o acesso do Facebook à sua VSL, garantindo que qualquer robô do
        Facebook ou pessoa que acesse sua VSL veja um vídeo aleatório que você
        escolher.
      </div>

      <section>
        <v-form v-model="valid" ref="form">
          <v-autocomplete
            v-model="form.enabledCountries"
            label="Paises permitidos"
            class="mb-4 rounded-lg limited"
            color="secondary"
            :items="countries"
            :rules="_rules"
            multiple
            rounded
            filled
            attach
          >
            <template v-slot:prepend-item>
              <v-list-item
                @click="
                  selectAll.countries = !selectAll.countries;
                  selectAllItems('enabledCountries', countries);
                "
              >
                <v-list-item-action>
                  <v-checkbox
                    v-model="selectAll.countries"
                    hide-details
                    readonly
                  />
                </v-list-item-action>

                <v-list-item-title>Selecionar todos</v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="form.enabledDevices"
            label="Dispositivos permitidss"
            class="mb-4 rounded-lg"
            color="secondary"
            :items="devices"
            :rules="_rules"
            multiple
            rounded
            filled
            attach
          />

          <v-autocomplete
            v-model="form.redirectVideo"
            label="Vídeo de redirecionamento"
            class="mb-4 rounded-lg"
            color="secondary"
            item-text="label"
            item-value="value"
            :items="videos"
            :rules="_rules"
            rounded
            filled
            attach
          />

          <div class="d-flex">
            <span class="text-body-1">Parâmetros</span>

            <v-spacer />

            <v-btn class="text-none" text @click="newParam()">
              Novo
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </div>

          <v-list
            v-if="form.urlParams && form.urlParams.length"
            class="transparent px-0"
            nav
          >
            <v-list-item
              v-for="(item, i) in form.urlParams"
              class="neutral3"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field
                    v-model="item.param"
                    placeholder="Digite o nome do parâmetro"
                    background-color="transparent"
                    hint="Parâmetro"
                    persistent-hint
                    dense
                    solo
                    flat
                    @keydown.space.prevent
                  />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field
                    v-model="item.value"
                    placeholder="Digite o valor"
                    background-color="transparent"
                    hint="Valor"
                    persistent-hint
                    dense
                    solo
                    flat
                    @keydown.space.prevent
                  />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn small icon @click="removeParam(i)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-switch v-model="form.blockBots" label="Bloquear robôs" />

          <v-switch
            v-model="form.blockFacebook"
            label="Bloquear Facebook"
            class="mb-4"
          />

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Cancelar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Salvar alterações </span>
            </v-btn>
          </div>
        </v-form>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      videoID: null,
      countries: [],
      devices: [],
      videos: [],
      selectAll: {
        countries: false,
      },
      form: {
        blockBots: false,
        blockFacebook: false,
        enabledCountries: [],
        enabledDevices: [],
        redirectVideo: null,
        urlParams: [],
      },
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  beforeMount() {
    this.getCloackerFields();
    this.getVideos();
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        let payload = {
          method: "uploadClocker",
          videoID: this.videoID,
          bloqueiaRobo: this.form.blockBots ? "1" : "0",
          bloqueiaFacebook: this.form.blockFacebook ? "1" : "0",
          paisesLiberados: this.form.enabledCountries,
          plataformaLiberados: ["undefined"],
          browsersLiberados: ["undefined"],
          dispostivosLiberados: this.form.enabledDevices,
          videoARedirecionar: this.form.redirectVideo,
          urlParams: {},
        };

        if (this.form.urlParams && this.form.urlParams.length) {
          this.form.urlParams.forEach((el) => {
            payload.urlParams[el.param] = el.value;
          });
        }

        payload.urlParams = JSON.stringify(payload.urlParams);

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async getCloackerFields() {
      const payload = {
        method: "getClockerFields",
      };

      const { data } = await request(payload);

      this.countries = data.countries;
      this.devices = data.dispositivo;
    },

    async getVideos() {
      const payload = {
        method: "getMyVideos",
      };

      const { data } = await request(payload);

      this.videos = data.videos
        .filter((el) => el.id !== this.video.id)
        .map((el) => ({
          value: el.id,
          label: el.name,
        }));
    },

    newParam() {
      this.form.urlParams = [
        ...this.form.urlParams,
        {
          chave: "",
          valor: "",
        },
      ];
    },

    removeParam(index) {
      this.form.urlParams.splice(index, 1);
    },

    selectAllItems(key, items) {
      if (this.selectAll.countries) {
        this.form[key] = structuredClone(items);
        return;
      }

      this.form[key] = [];
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.form = structuredClone(this.video.cloacker);
      this.videoID = this.video.id;
      this.selectAll.countries =
        this.form.enabledCountries.length === this.countries.length;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
