<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center">
        <div class="text-h6">Alterar miniatura</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-body-1 grey--text mb-6">
        Configuração de funcionalidade
      </div>

      <section>
        <div class="red--text mb-4">
          A utilização dessa função pode impactar em problemas de performance!
        </div>

        <v-form v-model="valid" ref="form">
          <v-card
            v-for="(item, i) in _inputs"
            class="mb-6 rounded-lg d-flex"
            color="neutral3"
            :key="i"
            flat
            @click="selectImg(item.ref)"
          >
            <div
              v-if="form[item.model]"
              class="d-flex fill-height justify-center align-center pa-2"
            >
              <v-img
                :src="form[item.model].objectURL"
                class="rounded-lg"
                width="10rem"
                height="8rem"
              >
              </v-img>
            </div>

            <div class="d-flex align-center justify-center fill-width">
              <FileInput
                v-model="form[item.model]"
                :ref="item.ref"
                :accept="'image/jpeg,image/jpg,image/png'"
                @input="submit(item.model)"
              >
                <div
                  class="d-flex flex-column justify-center align-center py-12"
                >
                  <div class="text-body-1">
                    <span> {{ item.label }} </span>
                  </div>

                  <div class="text-body-2 grey--text">
                    <span v-if="form[item.model]">
                      Clique e selecione uma imagem
                    </span>

                    <span v-else> Clique para alterar </span>
                  </div>
                </div>
              </FileInput>
            </div>

            <v-btn
              v-if="form[item.model]"
              class="file-float-bttn"
              color="red"
              height="1rem"
              width="1rem"
              depressed
              absolute
              right
              top
              fab
              @click.stop.prevent="removeThumb(item.model)"
            >
              <v-icon x-small>mdi-close</v-icon>
            </v-btn>
          </v-card>

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Fechar </span>
            </v-btn>
          </div>
        </v-form>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import FileInput from "@/components/fileInput/FileInput.vue";
import { request } from "@/services";

export default {
  components: {
    FileInput,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      videoID: null,
      inputs: [
        {
          ref: "defaultInput",
          label: "Thumb Inicial",
          model: "defaultThumb",
        },
        {
          ref: "holdInput",
          label: "Thumb Pausa",
          model: "holdThumb",
        },
        {
          ref: "finalInput",
          label: "Thumb Fim do final",
          model: "finalThumb",
        },
      ],
      form: {
        defaultThumb: null,
        holdThumb: null,
        finalThumb: null,
      },
    };
  },

  props: {
    video: { type: Object, required: true },

    hasAutoplay: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    ["video.thumbnails.holdThumb"]() {
      if (
        this.video.thumbnails.holdThumb &&
        this.video.thumbnails.holdThumb.length
      )
        this.form.holdThumb = {
          file: null,
          objectURL:
            this.video.thumbnails.holdThumb[
              this.video.thumbnails.holdThumb.length - 1
            ].url,
          id: this.video.thumbnails.holdThumb[
            this.video.thumbnails.holdThumb.length - 1
          ].id,
        };
    },
  },

  computed: {
    _inputs() {
      const inputs = structuredClone(this.inputs);

      if (!this.hasAutoplay) return inputs;

      inputs.splice(0, 1);
      return inputs;
    },
  },

  methods: {
    submit(model) {
      if (model === "defaultThumb") this.sendDefault();
      if (model === "holdThumb") this.sendHold();
      if (model === "finalThumb") this.sendFinal();
    },

    async sendDefault() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadThumbnailInicial",
          videoID: this.videoID,
          image: this.form.defaultThumb.file,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async removeDefault() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteThumbnailInicial",
          videoID: this.videoID,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");

        this.form.defaultThumb = null;
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async sendHold() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        if (this.form.holdThumb.id) {
          const payload = {
            method: "deleteThumb",
            videoID: this.videoID,
            thumbID: this.form.holdThumb.id,
          };

          await request(payload);

          delete this.form.holdThumb.id;
        }

        const payload = {
          method: "uploadThumbs",
          videoID: this.videoID,
          image: this.form.holdThumb.file,
          nome: "Nome",
          inicio: "00:00:00",
          fim: "00:00:00",
          ativaBotao: "1",
          botao_texto: "Texto do botão",
          botao_tamanho: "1",
          botao_link: "https://url.com",
          botao_corTexto: "#000000",
          botao_corBotao: "#8FDF1C",
          botao_corHover: "#0B1C22",
          botao_posicao: "1",
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async removeHold() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteThumb",
          videoID: this.videoID,
          thumbID: this.form.holdThumb.id,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");

        this.form.holdThumb = null;
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async sendFinal() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadThumbnailFinal",
          videoID: this.videoID,
          image: this.form.finalThumb.file,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async removeFinal() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteThumbnailFinal",
          videoID: this.videoID,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");

        this.form.finalThumb = null;
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    selectImg(ref) {
      this.$refs[ref][0].selectFiles();
    },

    removeThumb(model) {
      if (model === "defaultThumb") this.removeDefault();
      if (model === "holdThumb") this.removeHold();
      if (model === "finalThumb") this.removeFinal();
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.videoID = this.video.id;

      if (this.video.thumbnails.defaultThumb)
        this.form.defaultThumb = {
          file: null,
          objectURL: this.video.thumbnails.defaultThumb,
        };

      if (
        this.video.thumbnails.holdThumb &&
        this.video.thumbnails.holdThumb.length
      )
        this.form.holdThumb = {
          file: null,
          objectURL:
            this.video.thumbnails.holdThumb[
              this.video.thumbnails.holdThumb.length - 1
            ].url,
          id: this.video.thumbnails.holdThumb[
            this.video.thumbnails.holdThumb.length - 1
          ].id,
        };

      if (this.video.thumbnails.finalThumb)
        this.form.finalThumb = {
          file: null,
          objectURL: this.video.thumbnails.finalThumb,
        };

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
