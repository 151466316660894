import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Auth from "../views/auth/Auth.vue";
import Home from "../views/home/Home.vue";
import Signatures from "../views/signatures/Signatures.vue";
import Domains from "../views/domains/Domains.vue";
import Video from "../views/video/Video.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "",
        name: "Auth: Sign in",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/signIn/SignIn.vue"),
      },
      {
        path: "sign-up/:invite_id?",
        name: "Sign p",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/signUp/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        meta: { preventAuth: true },
        component: () =>
          import("../views/auth/children/forgotPassword/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiredAuth: true },
    component: Home,
  },
  {
    path: "/video/:id",
    name: "VideoManage",
    meta: { requiredAuth: true },
    component: Video,
  },
  {
    path: "/signatures",
    name: "Signatures",
    meta: { requiredAuth: true },
    component: Signatures,
  },
  {
    path: "/domains",
    name: "Domains",
    meta: { requiredAuth: true },
    component: Domains,
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
