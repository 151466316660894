<template>
  <v-app v-if="loaded" id="app">
    <v-app-bar v-if="_authenticated" color="neutral1" elevate-on-scroll app>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        class="mr-2"
        @click.stop="drawer = !drawer"
      />

      <v-responsive max-width="6rem">
        <v-img :src="require('@/assets/logo-white.svg')" contain />
      </v-responsive>

      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex px-4">
        <router-link
          v-for="(item, i) in routes"
          v-slot="{ href, navigate, isActive }"
          :to="item.path"
          :key="i"
          custom
        >
          <v-btn
            class="text-none"
            :color="isActive ? 'secondary' : 'white'"
            :href="href"
            :small="$vuetify.breakpoint.xsOnly"
            text
            @click="navigate()"
          >
            {{ item.label }}
          </v-btn>
        </router-link>
      </div>

      <v-spacer />

      <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center">
        <span class="text-body-2 font-weight-bold">{{ user.name }}</span>

        <v-avatar color="neutral2" size="46" class="ml-4">
          <v-icon>mdi-account-outline</v-icon>
        </v-avatar>
      </div>

      <v-btn
        class="ml-2 mr-2 mr-sm-4"
        :small="$vuetify.breakpoint.xsOnly"
        icon
        @click="logoff()"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      color="neutral2"
      temporary
      floating
      app
    >
      <v-img
        class="mx-16 my-4"
        :src="require('@/assets/logo-white.svg')"
        contain
      />

      <v-list nav>
        <router-link
          v-for="(item, i) in routes"
          v-slot="{ href, navigate, isActive }"
          :to="item.path"
          :key="i"
          custom
        >
          <v-list-item
            :active-class="isActive ? 'secondary--text' : 'white--text'"
            :href="href"
            @click="navigate()"
          >
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-main class="neutral">
      <v-banner v-if="_authenticated && !user.subscriber" two-line>
        <v-avatar slot="icon" color="amber" size="48">
          <v-icon light> mdi-crown </v-icon>
        </v-avatar>

        <div class="text-subtitle-1">
          Para desfrutar de todos os benefícios e recursos exclusivos do MyVSL é
          necessário uma assinatura, experimente gratuitamente por 14 dias.
          Basta clicar aqui, cadastrar seu cartão no Perfect Pay, e fique
          tranquilo, a cobrança será realizada somente após o período de 14
          dias.
        </div>

        <template v-slot:actions>
          <div class="ma-4">
            <v-btn
              class="text-none black--text"
              color="amber"
              @click="handleAssign()"
            >
              Assinar agora
            </v-btn>
          </div>
        </template>
      </v-banner>

      <router-view :class="!_authenticated || 'py-12'" />

      <v-btn
        v-if="_authenticated"
        class="fab"
        href="https://api.whatsapp.com/send?phone=5521969265499"
        target="_blank"
        color="green"
        :small="$vuetify.breakpoint.smAndDown"
        absolute
        bottom
        fixed
        right
        dark
        fab
      >
        <v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-whatsapp</v-icon>
      </v-btn>
    </v-main>

    <loader-hover v-if="loading" />

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar.vue";

export default {
  name: "App",

  data() {
    return {
      loaded: false,
      loading: false,
      drawer: false,
      routes: [
        {
          label: "Início",
          path: "/home",
        },
        {
          label: "Minhas assinaturas",
          path: "/signatures",
        },
        {
          label: "Meus domínios",
          path: "/domains",
        },
      ],
    };
  },

  components: {
    AlertBar,
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("get-user", this.getUser);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
    this.$root.$off("get-user", this.getUser);
  },

  computed: {
    ...mapState(["user"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut"]),

    // checks if the token is still valid for a session
    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const payload = {
            method: "checkUserToken",
          };

          await request(payload);
          this.getUser();
        } catch {
          this.logoff();
        }
      }

      this.loaded = true;
    },

    // get user and set storage
    async getUser(auth = false) {
      try {
        const payload = {
          method: "getUserData",
        };

        const { data } = await request(payload);
        this.handleUser(data, auth);
      } catch (err) {
        this.handleAlert({ title: err.data.message, type: "error" });
        this.logoff();
      }
    },

    handleUser(data, auth = false) {
      this.setUser({
        id: data.userData.id,
        name: data.userData.nome,
        email: data.userData.email,
        document: data.userData.cpfCnpj,
        createdAt: data.userData.registerDate,
        phone: data.userData.telefone,
        subscriber: data.userData.statusAssinatura,
        userToken: data.userData.userToken,
      });

      if (auth) return this.$router.push({ path: "/home" });

      return;
    },

    async handleAssign() {
      try {
        this.loading = true;

        const payload = {
          method: "buyNewAssinatura",
        };

        const { data } = await request(payload);

        window.open(data.buyLink);
      } catch (err) {
        this.handleAlert({ title: err.data.message, type: "error" });
      } finally {
        this.loading = false;
      }
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async logoff() {
      await this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fab {
  margin-bottom: 2.5rem;
}
</style>
