<template>
  <loader v-if="loading.content" />

  <v-container v-else>
    <v-btn class="text-none mb-4" to="/home" text>
      <v-icon left>mdi-arrow-left</v-icon> Voltar
    </v-btn>

    <div class="d-flex flex-column flex-md-row align-md-center mb-12">
      <div class="d-flex mb-4 mb-md-0">
        <div v-if="!editTitle" class="d-flex align-center">
          <div class="text-h4 font-weight-bold mr-2">
            {{ video.title }}
          </div>

          <v-btn icon @click="editTitle = true">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>

        <div v-else class="d-flex align-center">
          <v-text-field
            v-model="form.title"
            class="mr-2"
            placeholder="Novo título do vídeo"
            background-color="neutral2"
            hide-details="auto"
            dense
            solo
          />

          <v-btn class="mr-2" icon @click="cancelChangeTitle()">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn icon @click="submitChangeTitle()">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>
      </div>

      <v-spacer />

      <v-btn
        class="text-none black--text mr-md-2 mb-4 mb-md-0"
        color="primary"
        @click="handleEmbed()"
      >
        <v-icon left>mdi-code-tags</v-icon> Incorporar
      </v-btn>

      <v-btn
        class="text-none black--text mr-md-2 mb-4 mb-md-0"
        color="primary"
        @click="handlePublish()"
      >
        <v-icon left>
          {{ video.published ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
        </v-icon>
        <span> {{ video.published ? "Ocultar" : "Publicar" }} </span>
      </v-btn>

      <v-btn class="text-none" color="red" @click="handleDelete()">
        <v-icon left> mdi-delete </v-icon>
        Excluir
      </v-btn>
    </div>

    <v-row class="mb-6">
      <v-col cols="12" lg="3">
        <div class="text-h6 font-weight-bold mb-4">
          <v-icon left>mdi-cog</v-icon>

          Definições
        </div>

        <v-row>
          <v-col
            v-for="(item, i) in features"
            cols="12"
            :sm="i === 0 ? '12' : '6'"
            lg="12"
            :key="i"
          >
            <v-list-item
              v-ripple="false"
              class="neutral1 rounded-lg"
              :disabled="!user.subscriber"
              @click="handleAction(item.action)"
            >
              <v-list-item-title class="px-2">
                {{ item.title }}
              </v-list-item-title>

              <v-list-item-action v-if="!item.noSwitch" class="pr-4">
                <v-switch
                  v-model="item.enabled"
                  color="secondary"
                  :disabled="!user.subscriber"
                  dense
                  @click.stop.prevent="handleEnabled(item)"
                />
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="9">
        <div class="text-h6 font-weight-bold mb-4">
          <v-icon left>mdi-video-box</v-icon>

          Seu vídeo
        </div>

        <v-card
          v-if="!user.subscriber"
          class="mb-6 rounded-lg"
          color="neutral1"
          flat
        >
          <v-responsive :aspect-ratio="16 / 10">
            <div
              class="d-flex fill-height fill-width align-center justify-center"
            >
              <div class="text-body-1 grey--text">
                Você não possui uma assinatura ativa, ative para ter acesso a
                todos os benefícios!
              </div>
            </div>
          </v-responsive>
        </v-card>

        <v-card v-else class="mb-6 rounded-lg" color="transparent" flat>
          <v-responsive :aspect-ratio="16 / 10">
            <iframe
              v-if="!loading.iframe"
              width="100%"
              height="100%"
              title="MyVSL video player"
              allow="autoplay; encrypted-media"
              frameborder="0"
              :src="iframeSrc"
              allowfullscreen
            ></iframe>
          </v-responsive>
        </v-card>
      </v-col>
    </v-row>

    <section>
      <div class="text-h6 font-weight-bold mb-2">
        <v-icon left>mdi-chart-box-outline</v-icon>

        Dados de engajamento
      </div>

      <FilterPeriod
        :disabled="!user.subscriber"
        @change="handlePeriod($event)"
      />

      <loader v-if="loading.dashboard" />

      <section v-else>
        <v-row class="mb-6">
          <v-col v-for="(item, i) in analytics" cols="6" lg="4" :key="i">
            <v-card class="rounded-lg px-8 py-6" color="neutral1" Ï>
              <div class="text-caption">{{ item.text }}</div>
              <div class="text-h5 font-weight-bold secondary--text">
                {{ item.value }}
              </div>
            </v-card>
          </v-col>
        </v-row>

        <RetentionChart
          ref="retentionChart"
          :retention="retention"
          :videoID="video.youtubeID"
        />
      </section>
    </section>

    <EmbedDialog ref="embedDialog" />

    <EditStyle ref="editStyle" :video="video" @success="getVideoData()" />

    <Thumbnails
      ref="thumbnails"
      :video="video"
      :hasAutoplay="features[2].enabled"
      @success="getVideoData()"
    />

    <AutoPlay ref="autoPlay" :video="video" @success="getVideoData()" />

    <ResumePlay ref="resumePlay" :video="video" @success="getVideoData()" />

    <SmartProgress
      ref="smartProgress"
      :video="video"
      @success="getVideoData()"
    />

    <ActionButtons
      ref="actionButtons"
      :video="video"
      @success="getVideoData()"
    />

    <Cloacker ref="cloacker" :video="video" @success="getVideoData" />

    <DeleteVideo ref="deleteVideo" :video="video" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import EmbedDialog from "@/components/embedDialog/EmbedDialog";
import FilterPeriod from "@/components/filterPeriod/FilterPeriod";
import RetentionChart from "./partials/retentionChart/RetentionChart";
import EditStyle from "./partials/editStyle/EditStyle";
import Thumbnails from "./partials/thumbnails/Thumbnails";
import AutoPlay from "./partials/autoPlay/AutoPlay";
import SmartProgress from "./partials/smartProgress/SmartProgress";
import ResumePlay from "./partials/resumePlay/ResumePlay";
import ActionButtons from "./partials/actionButtons/ActionButtons";
import Cloacker from "./partials/cloacker/Cloacker";
import DeleteVideo from "./partials/deleteVideo/DeleteVideo";

export default {
  name: "Video",

  components: {
    EmbedDialog,
    FilterPeriod,
    RetentionChart,
    EditStyle,
    AutoPlay,
    Thumbnails,
    SmartProgress,
    ResumePlay,
    ActionButtons,
    Cloacker,
    DeleteVideo,
  },

  data() {
    return {
      loading: {
        content: true,
        iframe: true,
        dashboard: false,
      },
      editTitle: false,
      form: {
        title: "",
      },
      features: [
        { title: "Personalizar estilo", noSwitch: true, action: "editstyle" },
        { title: "Thumbnail", enabled: false, action: "thumbnails" },
        { title: "Início inteligente", enabled: false, action: "autoplay" },
        { title: "Continuar assistindo", enabled: false, action: "resumeplay" },
        {
          title: "Progresso inteligente",
          enabled: false,
          action: "smartprogress",
        },
        { title: "Botões de ação", enabled: false, action: "actionbuttons" },
        {
          title: "Definições do cloacker",
          enabled: false,
          action: "cloacker",
        },
      ],
      analytics: [
        { text: "Engajamento", value: "0" },
        { text: "Reprodução única", value: "0" },
        { text: "Total reproduzidos", value: "0" },
        { text: "Taxa de reprodução", value: "0" },
        { text: "Visualizações únicas", value: "0" },
        { text: "Total de visualizações", value: "0" },
      ],
      retention: {},
      video: {},
      iframeSrc: "",
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async getData() {
      if (this.user.subscriber) {
        await this.getVideoData();
        await this.getAnalytics();
        await this.getRetention();
      }

      this.loading.iframe = false;
      this.loading.content = false;
    },

    async getVideoData() {
      try {
        const payload = {
          method: "getVideoData",
          videoID: this.$route.params.id,
        };

        const { data } = await request(payload);

        const url = this.$store.getters.apiURL + "/";

        this.video = {
          id: data.videoData.id,
          title: data.videoData.name,
          createdAt: data.videoData.createdDate,
          published: data.videoData.published === "1",
          youtubeID: data.videoData.youtubeID,
          videoToken: data.videoData.videoToken,
          videoTime: data.videoData.videoTime,
          settings: {
            colors: {
              primary: data.videoData.videoConfigs.corPrincipal || "#8FDF1C",
              secondary: data.videoData.videoConfigs.corSecundaria || "#202632",
            },
            border: {
              show: data.videoData.videoConfigs.ativaBorda === "1",
              color: data.videoData.videoConfigs.corBorda || "#8FDF1C",
              size: data.videoData.videoConfigs.tamBorda || "1",
              rounded: data.videoData.videoConfigs.bordaArredondada === "1",
            },
            biggerPlayBtn: data.videoData.videoConfigs.playGrande === "1",
            playBtn: data.videoData.videoConfigs.playPequeno === "1",
            progressBar: data.videoData.videoConfigs.barraProgresso === "1",
            timer: data.videoData.videoConfigs.tempoVideo === "1",
            back: data.videoData.videoConfigs.voltar10s === "1",
            advance: data.videoData.videoConfigs.avancar10s === "1",
            volume: data.videoData.videoConfigs.volume === "1",
            fullscreen: data.videoData.videoConfigs.fullScreen === "1",
          },
          thumbnails: {
            ...(data.videoData.thumbs.inicial &&
              Object.keys(data.videoData.thumbs.inicial).length && {
                defaultThumb: url + data.videoData.thumbs.inicial.imagePath,
              }),
            ...(data.videoData.thumbs.final &&
              Object.keys(data.videoData.thumbs.final).length && {
                finalThumb: url + data.videoData.thumbs.final.imagePath,
              }),
            ...(data.videoData.thumbs.thumbs.length && {
              holdThumb: data.videoData.thumbs.thumbs.map((el) => ({
                url: url + el.image,
                id: el.id,
              })),
            }),
          },
          autoPlay: {
            startAt: data.videoData.smartAutoplay.inicio || "00:00:00",
            endAt: data.videoData.smartAutoplay.fim || "00:00:00",
            title:
              data.videoData.smartAutoplay.textoSuperior ||
              "Seu vídeo já começou",
            subtitle:
              data.videoData.smartAutoplay.textoInferior ||
              "Clique aqui para ouvir",
            cardColor: data.videoData.smartAutoplay.corBackground || "#132A34",
            textColor: data.videoData.smartAutoplay.corTexto || "#FFFFFF",
          },
          resumePlay: {
            title:
              data.videoData.continuarAssistindo.mensagem ||
              "Você já começou a assistir este vídeo!",
            continueLabel:
              data.videoData.continuarAssistindo.botaocontinuar ||
              "Continuar de onde parou",
            restartLabel:
              data.videoData.continuarAssistindo.botaorecomecar || "Recomeçar",
            textColor: data.videoData.continuarAssistindo.corTexto || "#FFFFFF",
            cardColor:
              data.videoData.continuarAssistindo.corBackground || "#132A34",
          },
          smartProgress: {
            height: data.videoData.smartProgress.altura || 4,
          },
          actionButtons: data.videoData.botoesDeAcao.botoesDeAcao
            .filter((el) => el.botao)
            .map((el) => ({
              id: el.id,
              type: el.botao.posicao === "0" ? "0" : "1",
              startTime: el.inicio,
              endTime: el.fim,
              text: el.botao.texto,
              color: el.botao.corBotao,
              textColor: el.botao.corTexto,
              position:
                el.botao.posicao === "0" ? "0" : Number(el.botao.posicao) - 1,
              redirectURL: el.botao.link,
            })),
          cloacker: {
            blockBots: data.videoData.cloacker.bloqueiaRobo === "1",
            blockFacebook: data.videoData.cloacker.bloqueiaFacebook === "1",
            enabledCountries: data.videoData.cloacker.paisesLiberados
              ? data.videoData.cloacker.paisesLiberados.split(",")
              : [],
            enabledDevices: data.videoData.cloacker.dispostivosLiberados
              ? data.videoData.cloacker.dispostivosLiberados.split(",")
              : [],
            redirectVideo: data.videoData.cloacker.videoARedirecionar,
            urlParams: [],
          },
        };

        if (data.videoData.cloacker.urlParams) {
          const urlParams = JSON.parse(data.videoData.cloacker.urlParams);

          if (Object.keys(urlParams).length) {
            this.video.cloacker.urlParams = Object.keys(urlParams).map(
              (key) => ({
                param: key,
                value: urlParams[key],
              })
            );
          }
        }

        this.features[1].enabled = data.videoData.videoFeatures.thumbs === "1";
        this.features[2].enabled =
          data.videoData.videoFeatures.smartAutoplay === "1";
        this.features[3].enabled =
          data.videoData.videoFeatures.continuarAssistindo === "1";
        this.features[4].enabled =
          data.videoData.videoFeatures.smartProgress === "1";
        this.features[5].enabled =
          data.videoData.videoFeatures.botoesDeAcao === "1";
        this.features[6].enabled =
          data.videoData.videoFeatures.cloacker === "1";

        await this.getIframe();
      } catch (error) {
        console.log(error);
      }
    },

    async getIframe() {
      try {
        const payload = {
          method: "getIframe",
          videoToken: this.video.videoToken,
          userToken: this.user.userToken,
        };

        const { data } = await request(payload, "/videoPlayer");

        const regex = /src="([^"]+)"/;
        const match = data.Iframe.match(regex);

        if (match) {
          let params = match[1] + "&adminMode=true";

          this.video.cloacker.urlParams.forEach((el) => {
            params += `&${el.param}=${el.value}`;
          });

          /* params = params.replace(
            "https://player.myvsl.com.br/",
            "http://localhost:9000/"
          ); */

          this.iframeSrc = params;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAnalytics(filters) {
      try {
        const payload = {
          method: "getAnalitycs",
          videoID: this.$route.params.id,
          ...(filters && {
            dataInicial: filters.initialDate,
            dataFinal: filters.finalDate,
          }),
        };

        const { data } = await request(payload);

        this.analytics[0].value = data.analytics.engajamento + "%";
        this.analytics[1].value = data.analytics.playsUnicos;
        this.analytics[2].value = data.analytics.playsTotal;
        this.analytics[3].value = data.analytics.playRate + "%";
        this.analytics[4].value = data.analytics.viewsUnicos;
        this.analytics[5].value = data.analytics.viewsTotal;
      } catch (error) {
        console.log(error);
      }
    },

    async getRetention(filters) {
      try {
        const payload = {
          method: "getRetencao",
          videoID: this.$route.params.id,
          ...(filters && {
            dataInicial: filters.initialDate,
            dataFinal: filters.finalDate,
          }),
        };

        const { data } = await request(payload);

        this.retention = {
          retention: this.popularRetentionWithSeconds(data.retencaoChart),
          videoTime: data.videoTime,
        };
      } catch (error) {
        console.log(error);
      }
    },

    popularRetentionWithSeconds(retentionObject) {
      const retentions = Object.keys(retentionObject);

      const retention = {};
      const startTime = retentions[0];
      const endTime = retentions[retentions.length - 1];

      const startDate = new Date(`1970-01-01T${startTime}Z`);
      const endDate = new Date(`1970-01-01T${endTime}Z`);
      const oneSecond = 1000;

      let currentTime = startDate;
      let lastValue = 0;
      let lestPercentage = 0;

      while (currentTime <= endDate) {
        const timeString = currentTime.toISOString().substr(11, 8);

        if (retentionObject[timeString]) {
          const { views, percent } = retentionObject[timeString];

          lastValue = views > 0 ? views : lastValue;
          lestPercentage = percent > 0 ? percent : lestPercentage;

          retention[timeString] = {
            time: timeString,
            views: lastValue,
            percentage: lestPercentage,
          };
        } else {
          retention[timeString] = {
            time: timeString,
            views: lastValue,
            percentage: lestPercentage,
          };
        }

        currentTime = new Date(currentTime.getTime() + oneSecond);
      }

      return retention;
    },

    handleAction(data) {
      if (data === "editstyle") this.$refs.editStyle.open();
      if (data === "thumbnails") this.$refs.thumbnails.open();
      if (data === "autoplay") this.$refs.autoPlay.open();
      if (data === "resumeplay") this.$refs.resumePlay.open();
      if (data === "actionbuttons") this.$refs.actionButtons.open();
      if (data === "smartprogress") this.$refs.smartProgress.open();
      if (data === "cloacker") this.$refs.cloacker.open();
    },

    async handleEnabled(event) {
      if (event.enabled) this.handleAction(event.action);

      try {
        const payload = {
          method: "uploadFeaturesConfig",
          videoID: this.video.id,
          thumbs: this.features[1].enabled ? "1" : "0",
          smartAutoplay: this.features[2].enabled ? "1" : "0",
          continuarAssistindo: this.features[3].enabled ? "1" : "0",
          smartProgress: this.features[4].enabled ? "1" : "0",
          botoesDeAcao: this.features[5].enabled ? "1" : "0",
          cloacker: this.features[6].enabled ? "1" : "0",
          turbo: "0",
          ganchos: "0",
          miniGanchos: "0",
        };

        await request(payload);
        this.getVideoData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async handlePublish() {
      try {
        const payload = {
          method: "publishVideo",
          videoID: this.video.id,
          published: this.video.published ? "0" : "1",
        };

        await request(payload);
        this.getVideoData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async handlePeriod(event) {
      this.loading.dashboard = true;

      await this.getAnalytics(
        event.initialDate && event.finalDate ? event : null
      );

      await this.getRetention(
        event.initialDate && event.finalDate ? event : null
      );

      this.loading.dashboard = false;
    },

    handleDelete() {
      this.$refs.deleteVideo.open();
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    handleEmbed() {
      this.$refs.embedDialog.open(this.video);
    },

    async submitChangeTitle() {
      if (!this.form.title) this.cancelChangeTitle();

      try {
        this.loading.content = true;

        const payload = {
          method: "changeVideoName",
          videoID: this.video.id,
          nome: this.form.title,
        };

        await request(payload);

        this.cancelChangeTitle();
        this.getVideoData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    cancelChangeTitle() {
      this.editTitle = false;
      this.form.title = "";
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
