<template>
  <v-container>
    <div class="d-flex flex-column flex-md-row align-md-center mb-8">
      <div class="text-h5 font-weight-bold mb-4 mb-md-0">Meus Videos</div>

      <v-spacer />

      <v-btn class="text-none black--text" color="primary" @click="openModal()">
        <span> Novo Video </span>
      </v-btn>
    </div>

    <v-text-field
      v-if="videos.length"
      v-model="search"
      label="Pesquisar vídeo"
      class="mb-4 rounded-lg"
      filled
      rounded
      dense
    />

    <!-- loader -->
    <loader v-if="loading" />

    <!-- videos -->
    <v-row v-else-if="videos.length">
      <v-col
        v-for="(item, i) in _videos"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        Ï
      >
        <v-card
          class="rounded-xl d-flex flex-row flex-sm-column"
          color="neutral1"
          height="100%"
        >
          <v-img
            :src="item.thumbnail || require('@/assets/play-myvsl.png')"
            :aspect-ratio="4 / 4"
            class="rounded-xl neutral2"
          />

          <section class="pa-4">
            <div class="text-h6 mb-n1">{{ item.title }}</div>

            <div class="text-caption grey--text mb-n1">
              {{ item.published ? "Publicado" : "Não publicado" }}
            </div>

            <div class="text-caption grey--text mb-4">{{ item.createdAt }}</div>

            <div class="d-flex">
              <v-btn
                class="text-none mr-4"
                color="neutral3"
                :to="`/video/${item.id}`"
                depressed
                rounded
              >
                Gerenciar video
              </v-btn>

              <v-spacer />

              <v-btn
                color="neutral3"
                depressed
                rounded
                @click="handleEmbed(item)"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </div>
          </section>
        </v-card>
      </v-col>
    </v-row>

    <!-- empty -->
    <div v-else>
      <section v-if="showPresentation" class="mb-8">
        <div class="text-h6 text-center mb-6">
          Seja bem vindo, assista o video abaixo
        </div>

        <v-row class="mb-6" justify="center">
          <v-col cols="12" md="11" lg="10">
            <v-card>
              <v-responsive :aspect-ratio="16 / 9">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/wlvDuSoGLSs?si=TNUHHv3UW-kavsbR"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>

        <div class="d-flex justify-center mb-8">
          <v-btn
            class="text-none black--text"
            color="primary"
            @click="handlePresentation()"
          >
            <span> Não exibir novamente </span>
          </v-btn>
        </div>

        <v-divider />
      </section>

      <section class="d-flex flex-column align-center justify-center">
        <div class="text-h6 mb-4">Você não possuí nenhum vídeo ainda</div>

        <v-btn
          class="text-none black--text"
          color="primary"
          @click="openModal()"
        >
          <span> Clique para criar um agora </span>
        </v-btn>
      </section>
    </div>

    <EmbedDialog ref="embedDialog" />

    <AddVideo ref="addVideo" @success="getVideos()" />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "@/services";
import EmbedDialog from "@/components/embedDialog/EmbedDialog.vue";
import AddVideo from "./partials/addVideo/AddVideo.vue";
const moment = require("moment");
moment.locale("pt-BR");

export default {
  name: "Home",

  data() {
    return {
      loading: true,
      search: "",
      videos: [],
    };
  },

  components: {
    EmbedDialog,
    AddVideo,
  },

  beforeMount() {
    this.getVideos();
  },

  computed: {
    ...mapState(["showPresentation"]),

    _videos() {
      if (!this.search) return this.videos;

      return this.videos.filter((el) =>
        el.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  methods: {
    ...mapActions(["setShowPresentation"]),

    async getVideos() {
      try {
        const payload = {
          method: "getMyVideos",
        };

        const { data } = await request(payload);

        this.videos = data.videos.map((el) => ({
          id: el.id,
          title: el.name,
          published: el.published === "1",
          createdAt: moment(el.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          youtubeID: el.youtubeID,
          thumbnail:
            Object.keys(el.thumbInicial).length > 0
              ? this.$store.getters.apiURL + "/" + el.thumbInicial.imagePath
              : "",
          videoToken: el.videoToken,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handlePresentation() {
      this.setShowPresentation(false);
    },

    handleEmbed(data) {
      this.$refs.embedDialog.open(data);
    },

    openModal() {
      this.$refs.addVideo.open();
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
