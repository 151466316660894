<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center">
        <div class="text-h6">Progresso inteligente</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-body-1 grey--text mb-6">
        A barra de progresso inteligente é uma ferramenta que acelera o avanço
        do tempo do vídeo, resultando em maior retenção por parte dos
        espectadores. Além disso, permite ajustar a altura da barra conforme
        necessário, levando em consideração que uma altura maior pode ser mais
        adequada para públicos mais velhos ou para visualização em dispositivos
        móveis, como smartphones.
      </div>

      <section>
        <v-form v-model="valid" ref="form">
          <div class="text-body-1 mx-2">
            Altura da barra: {{ form.height }}px
          </div>

          <v-slider
            v-model="form.height"
            max="24"
            min="1"
            :thumb-label="false"
            :thumb-size="24"
          />

          <v-sheet color="neutral3" class="mb-12 pa-6 rounded-lg">
            <div class="d-flex flex-column align-center justify-center">
              <div class="text-body-1 font-weight-bold mb-4">Demonstracão</div>

              <v-progress-linear
                v-model="progress.value"
                :height="form.height"
                :color="progress.color"
                striped
              />
            </div>
          </v-sheet>

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Cancelar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Salvar alterações </span>
            </v-btn>
          </div>
        </v-form>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      form: { height: "4" },
      progress: {
        value: 35,
        color: "",
      },
      model: 50,
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadSmartProgress",
          videoID: this.videoID,
          altura: this.form.height,
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.form.height = this.video.smartProgress.height;
      this.progress.color = this.video.settings.colors.primary;

      this.videoID = this.video.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
