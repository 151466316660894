<template>
  <v-container>
    <div class="d-flex flex-column flex-md-row align-md-center mb-8">
      <div class="text-h5 font-weight-bold mb-4 mb-md-0">
        Minhas assinaturas
      </div>

      <v-spacer />

      <v-btn
        class="text-none black--text"
        color="primary"
        @click="handleAssign()"
      >
        <span> Assinar agora </span>
      </v-btn>
    </div>

    <loader v-if="loading.content" />

    <v-data-table
      v-else
      :headers="headers"
      :items="signatures"
      hide-default-footer
      disable-pagination
      class="neutral1"
    />

    <loader-hover v-if="loading.assign" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, formatDate } from "@/utils";

export default {
  name: "Signatures",

  data() {
    return {
      loading: {
        content: true,
        assign: false,
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "ID da assinatura",
          value: "signatureID",
        },
        {
          text: "Descrição",
          value: "description",
        },
        { text: "Data de criação", value: "createdAt" },
        { text: "Próximo pagamento", value: "nextPayment" },
        { text: "Status", value: "status" },
        { text: "Valor", value: "value" },
      ],
      signatures: [],
    };
  },

  beforeMount() {
    this.getSignatures();
  },

  methods: {
    async getSignatures() {
      try {
        this.loading.content = true;

        const payload = {
          method: "listMyAssinaturas",
        };

        const { data } = await request(payload);

        this.signatures = data.assinaturas.map((el) => ({
          id: el.id,
          signatureID: el.assinaturaID,
          createdAt: formatDate(el.dataCriacao),
          description: el.descricao,
          nextPayment: formatDate(el.proxCobranca),
          status: el.statusAssinatura,
          valid: el.valid === "1",
          value: currencyMask(el.valor),
        }));
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    async handleAssign() {
      try {
        this.loading.assign = true;

        const payload = {
          method: "buyNewAssinatura",
        };

        const { data } = await request(payload);

        window.open(data.buyLink);
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading.assign = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
