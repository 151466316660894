<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Adicionar dominio</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section>
        <v-form v-model="valid" ref="form">
          <v-text-field
            v-model="form.url"
            label="Endereço URL"
            class="mb-4 rounded-lg"
            color="secondary"
            :rules="_rules"
            rounded
            filled
          />

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Cancelar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Cadastrar </span>
            </v-btn>
          </div>
        </v-form>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      form: {
        url: "",
      },
    };
  },

  props: {
    domains: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const domains = [this.form.url];
        const payload = {
          method: "uploadDominiosPermitidos",
          dominios: JSON.stringify([
            ...this.domains.map((el) => el.domain),
            ...domains,
          ]),
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    open() {
      this.form.domains = [];
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
