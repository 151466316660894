<template>
  <v-menu
    v-model="menu"
    content-class="mt-16"
    :close-on-content-click="false"
    :attach="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="model"
        v-bind="attrs"
        v-on="on"
        v-mask="'##:##:##'"
        :label="label"
        placeholder="##:##:##"
        class="rounded-lg"
        color="secondary"
        :hide-details="hideDetails"
        :disabled="disabled"
        :rules="rules"
        readonly
        rounded
        filled
      />
    </template>

    <v-card class="rounded-lg" color="neutral3" max-width="350px">
      <section class="pa-4 pb-0">
        <div class="text-body-2 mb-2">Selecione o tempo</div>

        <v-row justify="center" no-gutters>
          <v-col cols="3">
            <v-select
              v-model="time.hour"
              class="rounded-lg time-select"
              hint="Hora"
              :items="_hours"
              persistent-hint
              rounded
              filled
              dense
              @change="submit()"
            />
          </v-col>

          <span class="text-h6 mx-1 mt-1"> : </span>

          <v-col cols="3">
            <v-select
              v-model="time.minute"
              class="rounded-lg time-select"
              hint="Min"
              :items="_minutes"
              persistent-hint
              rounded
              filled
              dense
              @change="submit()"
            />
          </v-col>

          <span class="text-h6 mx-1 mt-1"> : </span>

          <v-col cols="3">
            <v-select
              v-model="time.second"
              class="rounded-lg time-select"
              hint="Seg"
              :items="_seconds"
              persistent-hint
              rounded
              filled
              dense
              @change="submit()"
            />
          </v-col>
        </v-row>
      </section>

      <div class="d-flex pa-4">
        <v-spacer />

        <v-btn class="text-none" text @click="close()"> Fechar </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      menu: false,
      model: "00:00:00",
      time: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      hours: Array.from(Array(100).keys()).map((num) =>
        num.toString().padStart(2, "0")
      ),
      minutes: Array.from(Array(60).keys()).map((num) =>
        num.toString().padStart(2, "0")
      ),
      seconds: Array.from(Array(60).keys()).map((num) =>
        num.toString().padStart(2, "0")
      ),
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },

    startValue: {
      type: String,
      default: "",
    },

    maxTime: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array,
      default: () => [],
    },
  },

  directives: {
    mask,
  },

  mounted() {
    if (this.startValue) {
      this.model = this.startValue;

      const [hour, minute, second] = this.startValue.split(":");

      this.time.hour = hour;
      this.time.minute = minute;
      this.time.second = second;
    }

    this.submit();
  },

  watch: {
    ["time.hour"]() {
      if (!this._totalTime) return;

      if (Number(this.time.hour) === this._totalTime.hour) {
        this.time.minute = "00";
        this.submit();
      }
    },

    ["time.minute"]() {
      if (!this._totalTime) return;

      if (Number(this.time.minute) === this._totalTime.minute) {
        this.time.second = "00";
        this.submit();
      }
    },

    disabled(val) {
      if (val) {
        this.model = "00:00:00";
        this.time = {
          hour: "00",
          minute: "00",
          second: "00",
        };

        this.submit();
      }
    },
  },

  computed: {
    _totalTime() {
      if (!this.maxTime) return;

      let [hour, minute, second] = this.maxTime.split(":");

      return {
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        second: parseInt(second, 10),
      };
    },

    _hours() {
      if (!this._totalTime) return this.hours;

      const hour = this._totalTime.hour;
      return this.hours.filter((el) => Number(el) <= hour);
    },

    _minutes() {
      if (this._totalTime) {
        const minute = this._totalTime.minute;

        if (Number(this.time.hour) === this._totalTime.hour)
          return this.minutes.filter((el) => Number(el) <= minute);
      }

      return this.minutes;
    },

    _seconds() {
      if (this._totalTime) {
        const second = this._totalTime.second;

        if (
          Number(this.time.hour) === this._totalTime.hour &&
          Number(this.time.minute) === this._totalTime.minute
        )
          return this.seconds.filter((el) => Number(el) <= second);
      }

      return this.seconds;
    },
  },

  methods: {
    submit() {
      this.model = [this.time.hour, this.time.minute, this.time.second].join(
        ":"
      );

      this.$emit("input", this.model);
    },

    close() {
      this.menu = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
